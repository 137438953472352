export const CompleteProfileValidationsMixin = {
  data() {
    return {
      // Validations
      validationRules: [
        {
          // step 0
          // no validations
        },
        {
          // step 1
          // no validations
          image: [v => !!v || this.$i18n.t("Image is required")]
        },
        {
          // step 2
          firstname: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("First Name shouldn't contain spaces only"),
            v =>
              v.length <= 25 ||
              this.$i18n.t("First Name must be less than 25 characters")
          ],
          middlename: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Middle Name shouldn't contain spaces only"),
            v =>
              v.length <= 25 ||
              this.$i18n.t("Middle Name must be less than 25 characters")
          ],
          familyname: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Family Name shouldn't contain spaces only"),
            v =>
              v.length <= 25 ||
              this.$i18n.t("Family Name must be less than 25 characters")
          ],
          education_level: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Education Level shouldn't contain spaces only")
          ],
          occupation: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Occupation shouldn't contain spaces only")
          ],
          business_address: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Business Address shouldn't contain spaces only")
          ],
          tel: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(\+?)[0-9]+$/.test(v) ||
              this.$i18n.t("Please enter a valid format"),
            v =>
              v.length >= 5 ||
              this.$i18n.t(
                "Home Tel. Number must be more than or equal to 5 digits."
              ),
            v =>
              v.length <= 20 ||
              this.$i18n.t(
                "Home Tel. Number must be less than or equal to 20 digits."
              )
          ],
          mobile: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(\+?)[0-9]+$/.test(v) ||
              this.$i18n.t("Please enter a valid format"),
            v =>
              v.length >= 5 ||
              this.$i18n.t(
                "Mobile Number must be more than or equal to 5 digits."
              ),
            v =>
              v.length <= 15 ||
              this.$i18n.t(
                "Mobile Number must be less than or equal to 15 digits."
              )
          ],
          email: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /.+@.+\..+/.test(v) ||
              this.$i18n.t("Please enter a valid email format")
          ]
          // nationality: [
          //   v => !!v || "This field is Required",
          //   v => (v && /^\d+$/.test(v)) || "This field only accept numbers",
          //   v => (v && /^\w+$/.test(v)) || "Please enter a valid format"
          // ]
        },
        {
          // step 3
          firstname: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("First Name shouldn't contain spaces only"),
            v =>
              v.length <= 25 ||
              this.$i18n.t("First Name must be less than 25 characters")
          ],
          middlename: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Middle Name shouldn't contain spaces only"),
            v =>
              v.length <= 25 ||
              this.$i18n.t("Middle Name must be less than 25 characters")
          ],
          familyname: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Family Name shouldn't contain spaces only"),
            v =>
              v.length <= 25 ||
              this.$i18n.t("Family Name must be less than 25 characters")
          ],
          education_level: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Education Level shouldn't contain spaces only")
          ],
          occupation: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Occupation shouldn't contain spaces only")
          ],
          business_address: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Business Address shouldn't contain spaces only")
          ],
          tel: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(\+?)[0-9]+$/.test(v) ||
              this.$i18n.t("Please enter a valid format"),
            v =>
              v.length >= 5 ||
              this.$i18n.t(
                "Home Tel. Number must be more than or equal to 5 digits."
              ),
            v =>
              v.length <= 20 ||
              this.$i18n.t(
                "Home Tel. Number must be less than or equal to 20 digits."
              )
          ],
          mobile: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(\+?)[0-9]+$/.test(v) ||
              this.$i18n.t("Please enter a valid format"),
            v =>
              v.length >= 5 ||
              this.$i18n.t(
                "Mobile Number must be more than or equal to 5 digits."
              ),
            v =>
              v.length <= 15 ||
              this.$i18n.t(
                "Mobile Number must be less than or equal to 15 digits."
              )
          ],
          email: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /.+@.+\..+/.test(v) ||
              this.$i18n.t("Please enter a valid email format")
          ],
          parents_relationship: [
            v => !!v || this.$i18n.t("This field is required")
          ],
          parents_relationship_other: [
            v => !!v || this.$i18n.t("This field is required")
          ]
        },
        {
          // step 4
          name: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Name shouldn't contain spaces only"),
            v =>
              v.length <= 50 ||
              this.$i18n.t("Name must be less than 50 characters")
          ],
          gender: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Gender shouldn't contain spaces only")
          ],
          mobile: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(\+?)[0-9]+$/.test(v) ||
              this.$i18n.t("Please enter a valid format"),
            v =>
              v.length >= 5 ||
              this.$i18n.t(
                "Mobile Number must be more than or equal to 5 digits."
              ),
            v =>
              v.length <= 15 ||
              this.$i18n.t(
                "Mobile Number must be less than or equal to 15 digits."
              )
          ],
          date_of_birth: [
            v => !!v || this.$i18n.t("Date of Birth is required")
          ],
          school: [
            v => !!v || this.$i18n.t("School is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("School shouldn't contain spaces only")
          ]
        },
        {
          // step 5
          school_name: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("School Name shouldn't contain spaces only")
          ],
          city: [v => !!v || this.$i18n.t("This field is required")],
          level_completed: [v => !!v || this.$i18n.t("This field is required")],
          repeated_grade_name: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t(
                "Repeated grade details shouldn't contain spaces only"
              )
          ],
          applicant_expelled_details: [
            v => !!v || this.$i18n.t("This field is required"),
            v =>
              /^(.*[^ ].*)/.test(v) ||
              this.$i18n.t("Details shouldn't contain spaces only")
          ]
        },
        {
          // step 6
          // special_needs_details: [v => !!v || "This field is required"]
        },
        {
          // step 7
        },
        {
          // step 8
        },
        {
          // step 9
          know_about_us: [v => !!v || this.$i18n.t("This field is required")],
          know_about_us_other: [
            v => !!v || this.$i18n.t("This field is required")
          ],
          participate_voluntary_details: [
            v => !!v || this.$i18n.t("This field is required")
          ]
        }
      ],
      validationRulesApplicant: {
        firstname: [
          v => !!v || this.$i18n.t("This field is required"),
          v =>
            /^(.*[^ ].*)/.test(v) ||
            this.$i18n.t("First name shouldn't contain spaces only"),
          v =>
            v.length <= 25 ||
            this.$i18n.t(
              "You have reached your maximum limit of characters allowed"
            )
        ],
        fathername: {
          // v =>
          //   /^(.*[^ ].*)/.test(v) ||
          //   "Family Name shouldn't contain spaces only",
          maxLength: v => {
            if (v) {
              return (
                v.length <= 25 ||
                this.$i18n.t(
                  "You have reached your maximum limit of characters allowed"
                )
              );
            } else return true;
          }
        },
        grandFatherName: {
          // v =>
          //   /^(.*[^ ].*)/.test(v) ||
          //   "Family Name shouldn't contain spaces only",
          maxLength: v => {
            if (v) {
              return (
                v.length <= 25 ||
                this.$i18n.t(
                  "You have reached your maximum limit of characters allowed"
                )
              );
            } else return true;
          }
        },
        familyname: [
          v => !!v || this.$i18n.t("This field is required"),
          v =>
            /^(.*[^ ].*)/.test(v) ||
            this.$i18n.t("Family name shouldn't contain spaces only"),
          v =>
            v.length <= 25 ||
            this.$i18n.t(
              "You have reached your maximum limit of characters allowed"
            )
        ],
        date_of_birth: [v => !!v || this.$i18n.t("This field is required")],
        date_of_birth_hijri: [
          v => !!v || this.$i18n.t("Date of birth Hijri is required")
        ],
        gender: [v => !!v || this.$i18n.t("Gender is required")],
        place_of_birth: [
          v => !!v || this.$i18n.t("This field is required"),
          v =>
            /^(.*[^ ].*)/.test(v) ||
            this.$i18n.t("Place of birth shouldn't contain spaces only"),
          v =>
            v.length <= 25 ||
            this.$i18n.t(
              "You have reached your maximum limit of characters allowed"
            )
        ],
        nationality: [v => !!v || this.$i18n.t("This field is required")],
        // previous_school: [
        //   v =>
        //     v.length <= 50 || "Previous School must be less than 50 characters"
        // ],
        native_language: [v => !!v || this.$i18n.t("This field is required")],
        grade_level: [v => !!v || this.$i18n.t("This field is required")],
        // curriculm: [
        //   v => v.length <= 50 || "Curriculm must be less than 50 characters"
        // ],
        contact_person_name: [
          v => !!v || this.$i18n.t("This field is required"),
          v =>
            /^(.*[^ ].*)/.test(v) ||
            this.$i18n.t("Contact person name shouldn't contain spaces only"),
          v =>
            v.length <= 25 ||
            this.$i18n.t(
              "You have reached your maximum limit of characters allowed"
            )
        ],
        contact_person_mobile: [
          v => !!v || this.$i18n.t("This field is required"),
          v =>
            /.+@.+\..+/.test(v) || this.$i18n.t("Please enter a valid format")
        ],
        contact_person_email: [
          v => !!v || this.$i18n.t("This field is required"),
          v =>
            /.+@.+\..+/.test(v) ||
            this.$i18n.t("Please enter a valid email format")
        ],
        contact_person_relation_ship: [
          v => !!v || this.$i18n.t("This field is required")
        ],
        contact_person_relation_ship_other: [
          v => !!v || this.$i18n.t("This field is required")
        ],
        contact_person_communication_method: [
          v => !!v || this.$i18n.t("This field is required")
        ]
      },
      validation: {
        required: v => {
          if (v)
            return !!v.toString() || this.$i18n.t("This field is Required");
          else return this.$i18n.t("This field is Required");
        },
        // required: v => !!v || "This field is required",
        numeric: v => {
          if (v)
            return (
              (v && /^\d+$/.test(v)) ||
              this.$i18n.t("This field only accept numbers")
            );
          else return true;
        },
        alphaNum: v =>
          (v && /^\w+$/.test(v)) || this.$i18n.t("Please enter a valid format"),
        maxLength50: v => {
          if (v) {
            return (
              v.length <= 50 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },

        email: v => {
          if (v)
            return (
              /.+@.+\..+/.test(v) || this.$i18n.t("Please enter a valid format")
            );
          else return true;
        }
      }
    };
  },
  methods: {
    checkNationalIdUnique(
      student_national_id,
      father_national_id,
      mother_national_id
    ) {
      if (
        student_national_id != "" &&
        (student_national_id == father_national_id ||
          student_national_id == mother_national_id)
      ) {
        return this.$i18n.t("National id already exists");
      }
      return true;
    },
    checkNationalIdUniqueFather(
      student_national_id,
      father_national_id,
      mother_national_id
    ) {
      if (
        father_national_id != "" &&
        (student_national_id == father_national_id ||
          father_national_id == mother_national_id)
      ) {
        return this.$i18n.t("National id already exists");
      }
      return true;
    },
    checkNationalIdUniqueMother(
      student_national_id,
      father_national_id,
      mother_national_id
    ) {
      if (
        mother_national_id != "" &&
        (father_national_id == mother_national_id ||
          student_national_id == mother_national_id)
      ) {
        return this.$i18n.t("National id already exists");
      }
      return true;
    },
    checkThatValuesNotSame(val1, val2, type) {
      if (val1 != val2) {
        return true;
      } else {
        if (type == "mobile") {
          return this.$i18n.t(
            this.$i18n.t("Father mobile and mother mobile cannot be the same")
          );
        } else {
          return this.$i18n.t(
            this.$i18n.t("Father email and mother email cannot be the same")
          );
        }
      }
    }
    // fatherNationalIdCheck() {
    //   if (this.father_saudi == true) {
    //     return this.studentObject.father.national_id == ""
    //       ? "This field is required"
    //       : /^\d+$/.test(this.studentObject.father.national_id)
    //       ? true
    //       : "This field only accept numbers";
    //   } else {
    //     return true;
    //   }
    // },
    // fatherIqamaNumberCheck() {
    //   if (this.father_saudi == true) {
    //     return true;
    //   } else {
    //     return this.studentObject.father.parent_iqama_no == ""
    //       ? "This field is required"
    //       : /^\w+$/.test(this.studentObject.father.parent_iqama_no)
    //       ? true
    //       : "Please enter a valid format";
    //   }
    // },
    // fatherExpiryDateCheck() {
    //   if (this.father_saudi == true) {
    //     return true;
    //   } else {
    //     return this.studentObject.father.parent_iqama_no_expire_date == ""
    //       ? "This field is required"
    //       : true;
    //   }
    // },
    // motherNationalIdCheck() {
    //   if (this.mother_saudi) {
    //     return this.studentObject.mother.national_id == ""
    //       ? "This field is required"
    //       : /^\d+$/.test(this.studentObject.mother.national_id)
    //       ? true
    //       : "This field only accept numbers";
    //   } else {
    //     return true;
    //   }
    // },
    // motherIqamaNumberCheck() {
    //   if (this.mother_saudi) {
    //     return true;
    //   } else {
    //     return this.studentObject.mother.parent_iqama_no == ""
    //       ? "This field is required"
    //       : /^\w+$/.test(this.studentObject.mother.parent_iqama_no)
    //       ? true
    //       : "Please enter a valid format";
    //   }
    // },
    // motherExpiryDateCheck() {
    //   if (this.mother_saudi) {
    //     return true;
    //   } else {
    //     return this.studentObject.mother.parent_iqama_no_expire_date == ""
    //       ? "This field is required"
    //       : true;
    //   }
    // }
  }
};
