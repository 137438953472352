import axios from "axios";

export const CompleteProfileMixin = {
  data() {
    return {
      mobileRules: {
        required: (v) => !!v || this.$i18n.t("Mobile Number is required"),
        foramt: (v) =>
          /^(\+?)[0-9]+$/.test(v) ||
          this.$i18n.t("Please enter a valid format"),
      },
      dataObject: {
        Codes: [],
        nationalities: [],
        religion: [
          {
            key: this.$i18n.t("Muslim"),
            value: "muslim",
          },
          {
            key: this.$i18n.t("Non Muslim"),
            value: "non-muslim",
          },
        ],
        level_completed: [],
        city: [
          { key: this.$i18n.t("riyadh"), value: "riyadh" },
          { key: this.$i18n.t("mecca"), value: "mecca" },
        ],
        communicable_diseases: [],
        other_diseases: [],
      },
    };
  },
  methods: {
    fatherNationalityOnSelect() {
      if (this.studentData.father.nationality_id == 187) {
        this.father_saudi = true;
        this.studentData.father.parent_iqama_no = "";
        this.studentData.father.parent_iqama_no_expire_date = "";
      } else {
        this.activateDatepicker();
        this.father_saudi = false;
        this.studentData.father.national_id = "";
      }
    },
    motherNationalityOnSelect() {
      if (this.studentData.mother.nationality_id == 187) {
        this.mother_saudi = true;
        this.studentData.mother.parent_iqama_no = "";
        this.studentData.mother.parent_iqama_no_expire_date = "";
      } else {
        this.activateDatepicker2();
        this.mother_saudi = false;
        this.studentData.mother.national_id = "";
      }
    },
    /* father expiry Iqama */
    activateDatepicker() {
      var _this = this;
      $(document).ready(function () {
        $("#expiry").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              _this.studentData.father.parent_iqama_no_expire_date = dateM;
            } else {
              _this.studentData.father.parent_iqama_no_expire_date = "";
            }
          },
        });
      });
    },
    activateDatepicker2() {
      var _this = this;
      $(document).ready(function () {
        $("#motherIqamaexpiry").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              _this.studentData.mother.parent_iqama_no_expire_date = dateM;
            } else {
              _this.studentData.mother.parent_iqama_no_expire_date = "";
            }
          },
        });
      });
    },

    activateDatepicker3() {
      let _this = this;
      $(document).ready(function () {
        $("#gregorianbirth").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.applicantObject.date_of_birth = dateH;
            } else {
              _this.applicantObject.date_of_birth = "";
            }
          },
        });
      });
      $(document).ready(function () {
        $("#hijri").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          maxDate: 0,
          calendar: $.calendars.instance("islamic"),
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.applicantObject.date_of_birth_hijri = dateH;
            } else {
              _this.applicantObject.date_of_birth_hijri = "";
            }
          },
        });
      });
      $(document).ready(function () {
        $("#iqamaDate").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.applicantObject.iqama_expiry_date = dateH;
            } else {
              _this.applicantObject.iqama_expiry_date = "";
            }
          },
        });
      });
      this.studentData.siblings.forEach((item, index) => {
        $(document).ready(function () {
          $("#gregorian" + index).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            maxDate: 0,
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.studentObject.siblings[index].date_of_birth = dateH;
              } else {
                _this.studentObject.siblings[index].date_of_birth = "";
              }
            },
          });
        });
      });
    },

    mobileRule(country_code, mobile) {
      if (country_code == 187) {
        return !/^((?!0)[0-9]{9})*$/.test(mobile)
          ? this.$i18n.t(
              "This field must be only 9 numbers and not start with 0."
            )
          : true;
      } else {
        if (mobile.length <= 5 || mobile.length >= 15) {
          return this.$i18n.t("Mobile Number must be between 5 to 15 digits.");
        }
        return true;
      }
    },
    onFileSelected(event) {
      this.imageError = false;
      this.logoError = "";
      document.getElementById("preloader-custom").classList.remove("hidden");
      const fd = new FormData();
      this.selectedFile = event.target.files[0];
      if (this.selectedFile != undefined) {
        fd.append("image", this.selectedFile);
        axios
          .post(this.getApiUrl + "/uploadFormImage", fd, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            if (response.data.status.error) {
              this.logoError = response.data.status.validation_errors.image[0];
              this.logoUploaded = false;
            } else {
              this.studentData.image = response.data.data.url;
              this.logoUploaded = true;
              this.valid[1] = true;
            }
            document.getElementById("preloader-custom").classList.add("hidden");
          });
      } else {
        document.getElementById("preloader-custom").classList.add("hidden");
      }
    },
    next() {
      if (this.currentStep < 7) {
        if (this.currentStep <= 6) {
          this.$refs["form" + this.currentStep].validate();
        }
        if (this.currentStep == 6) {
          if (this.valid[6]) {
            if (this.studentData.medical_history) {
              this.currentStep++;
            } else {
              this.currentStep = 7;
            }
          }
        } else {
          if (
            this.valid[parseInt(this.currentStep)] &&
            this.logoUploaded == true
          )
            this.currentStep++;
        }
      } else if (this.currentStep == 7) {
        this.validationMsg = "";
        this.studentAttachments.forEach((attachment, index) => {
          this.$set(this.studentAttachments, index, {
            ...this.studentAttachments[index],
            validation_error: "",
          });
          if (attachment.required && !attachment.uploaded_file_id) {
            this.$set(this.studentAttachments, index, {
              ...this.studentAttachments[index],
              validation_error: this.$i18n.t("This field is required"),
            });

            this.validationMsg = this.$i18n.t(
              "Please complete required (" + attachment.name + ")"
            );
          }
        });
        if (!this.validationMsg) {
          this.currentStep++;
        }
      } else if (this.currentStep == 9) {
        this.$refs["form9"].validate();
      }
      if (this.currentStep == 1) {
        if (this.studentData.image == "") {
          this.logoError = "Photo is required.";
        } else {
          this.valid[1] = true;
          this.currentStep = 2;
        }
      }
    },
    previous() {
      if (this.currentStep == 9) {
        if (this.studentData.medical_history) {
          this.currentStep--;
        } else {
          this.currentStep = 6;
        }
      } else {
        if (this.currentStep > 1) {
          this.currentStep--;
        }
      }
    },
    selectPage(p) {
      if (this.valid || this.currentStep == 1) {
        if (this.currentStep == 6) {
          if (this.studentData.medical_history) {
            this.currentStep = p;
          } else {
            this.currentStep = 9;
          }
        } else {
          this.currentStep = p;
        }
      }
    },
    onFocus() {
      //   this.$refs.form.resetValidation();
    },
    addSibling() {
      this.studentData.siblings.push({
        name: "",
        gender: "",
        mobile: "",
        country_id: 187,
        date_of_birth: "",
        school: "",
      });
      var _this = this;
      this.studentData.siblings.forEach((item, index) => {
        $(document).ready(function () {
          $("#gregorian" + index).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            maxDate: 0,
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.studentData.siblings[index].date_of_birth = dateH;
              } else {
                _this.studentData.siblings[index].date_of_birth = "";
              }
            },
          });
        });
      });
    },
    addAcademicHistory() {
      this.studentData.academic_history.push({
        school_name: "",
        city: "",
        level_completed: "",
        levels_attended: [],
      });
    },
    submitProfile() {
      // prevent double click
      if (this.valid[9]) {
        this.disableSubmitButton = true;
        if (this.CompleteStudentProfileEdit == true) {
          delete this.studentData.father.nationality;
          delete this.studentData.mother.nationality;
          axios
            .post(
              this.getApiUrl + "/updateStudent/" + this.national_id,
              {
                applicantData: this.applicantObject,
                studentData: this.studentData,
              },
              {}
            )
            .then((response) => {
              if (response.data.status.error == false) {
                // this.submit(true, "green", response.data.status.message);
                this.submitted = true;
              } else {
                this.validation_errors = response.data.status.validation_errors;
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/students/store",
              {
                applicant_code: this.applicant_code,
                studentData: this.studentData,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                this.submitted = true;
              }
            });
        }
      } else {
        this.$refs["form9"].validate();
      }
    },
    canSubmit() {
      return this.valid[2] && this.valid[3] && this.valid[4] && this.valid[5];
    },
    returnIfNotValidStep() {
      for (let index = 2; index < 6; index++) {
        if (!this.valid[index]) {
          this.currentStep = index;
          break;
        }
      }
    },
  },
  mounted() {
    axios.get(this.getApiUrl + "/getCodes").then((response) => {
      this.dataObject.Codes = response.data.data;
    });

    axios.get(this.getApiUrl + "/getCountries").then((response) => {
      this.dataObject.nationalities = response.data.data;
    });

    axios.get(this.getApiUrl + "/getCities").then((response) => {
      this.dataObject.city = response.data.data.cities;
    });

    axios.get(this.getApiUrl + "/getGrades").then((response) => {
      this.dataObject.level_completed = response.data.data.grades;
    });

    axios.get(this.getApiUrl + "/students/getDiseases").then((response) => {
      this.dataObject.communicable_diseases =
        response.data.data.communicable_diseases;
      this.dataObject.other_diseases = response.data.data.other_diseases;
    });
    this.applicant_code = this.$router.currentRoute.params.applicant_code;
    if (this.CompleteStudentProfileEdit) {
      this.national_id = this.$router.currentRoute.params.national_id;
      axios
        .get(this.getApiUrl + "/editStudent/" + this.national_id, {})
        .then((response) => {
          if (response.data.status.error) {
            window.location = "/404";
          } else {
            this.applicantObject = response.data.data.applicantObject;
            this.studentData = response.data.data.studentObject;
            axios
              .get(
                this.getApiUrl +
                  "/getGrades?student_year=" +
                  this.studentData.year_id
              )
              .then((response) => {
                this.dataObject.grades = response.data.data.grades;
                this.activateDatepicker3();
              });
            this.applicantObject.contact_person.communication_method =
              this.applicantObject.contact_person.communication_method.split(
                ","
              );
            this.onOther();
            this.OnotherPrentRelationship();
            this.fatherNationalityOnSelect();
            // this.participateFieldInput();
            if (this.applicantObject.nationality == 187) {
              this.saudi = true;
            } else {
              this.saudi = false;
            }
          }
        });
    } else {
      axios
        .get(
          this.getApiUrl +
            "/students/getApplicantData?applicant_code=" +
            this.applicant_code
        )
        .then((response) => {
          if (response.data.status.error) {
            // window.location = "/404";
            this.messageShow = true;
          } else {
            this.applicantObject = response.data.data;
          }
        });
    }
  },
};
